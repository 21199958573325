import * as React from "react";

function Share(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={33}
      viewBox="0 0 32 33"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22 20.075a4.984 4.984 0 00-3.577 1.512l-5.762-3.704a4.98 4.98 0 000-3.615l5.762-3.704a4.994 4.994 0 10-1.084-1.682l-5.762 3.705a5 5 0 100 6.977l5.762 3.704A5 5 0 1022 20.075z" />
    </svg>
  );
}

export default Share;
