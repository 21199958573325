import * as React from "react";

function Twitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={19}
      viewBox="0 0 25 19"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path d="M24.66 2.512c-.878.37-1.823.62-2.816.733A4.72 4.72 0 0024.001.667c-.963.543-2.016.925-3.115 1.13A4.99 4.99 0 0018.103.385a5.133 5.133 0 00-3.114.49 4.767 4.767 0 00-2.155 2.189 4.445 4.445 0 00-.306 2.983 14.51 14.51 0 01-5.6-1.413 13.82 13.82 0 01-4.51-3.455 4.479 4.479 0 00-.663 2.342c0 .767.198 1.523.579 2.199.38.676.93 1.253 1.602 1.68a5.092 5.092 0 01-2.221-.584v.06c0 1.076.391 2.119 1.109 2.951a4.958 4.958 0 002.826 1.617 5.195 5.195 0 01-2.216.08A4.677 4.677 0 005.18 13.84c.822.58 1.814.902 2.837.92a10.18 10.18 0 01-6.092 1.994c-.391 0-.782-.021-1.17-.065a14.413 14.413 0 007.517 2.093c9.024 0 13.957-7.098 13.957-13.254 0-.2-.006-.402-.015-.602a9.71 9.71 0 002.446-2.41l.002-.003z" />
    </svg>
  );
}

export default Twitter;
