import * as React from "react";

function Preview(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={33}
      viewBox="0 0 32 33"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M27 6.075v5a1 1 0 01-1.707.707l-5-5A1 1 0 0121 5.075h5a1 1 0 011 1zM6.707 20.368A1 1 0 005 21.075v5a1 1 0 001 1h5a1 1 0 00.707-1.707l-5-5zm19.676-.217a1 1 0 00-1.09.217l-5 5A1 1 0 0021 27.075h5a1 1 0 001-1v-5a1 1 0 00-.617-.924zM11 5.075H6a1 1 0 00-1 1v5a1 1 0 001.707.707l5-5A1 1 0 0011 5.075z" />
    </svg>
  );
}

export default Preview;
