import { type SVGProps } from "react";

const Check = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.83268 13.1537L13.7077 7.27873L12.541 6.11206L7.83268 10.8204L5.45768 8.44539L4.29102 9.61206L7.83268 13.1537ZM8.99935 17.6537C7.84657 17.6537 6.76324 17.4348 5.74935 16.9971C4.73546 16.5598 3.85352 15.9662 3.10352 15.2162C2.35352 14.4662 1.7599 13.5843 1.32268 12.5704C0.884905 11.5565 0.666016 10.4732 0.666016 9.32039C0.666016 8.16762 0.884905 7.08428 1.32268 6.07039C1.7599 5.05651 2.35352 4.17456 3.10352 3.42456C3.85352 2.67456 4.73546 2.08067 5.74935 1.64289C6.76324 1.20567 7.84657 0.987061 8.99935 0.987061C10.1521 0.987061 11.2355 1.20567 12.2493 1.64289C13.2632 2.08067 14.1452 2.67456 14.8952 3.42456C15.6452 4.17456 16.2388 5.05651 16.676 6.07039C17.1138 7.08428 17.3327 8.16762 17.3327 9.32039C17.3327 10.4732 17.1138 11.5565 16.676 12.5704C16.2388 13.5843 15.6452 14.4662 14.8952 15.2162C14.1452 15.9662 13.2632 16.5598 12.2493 16.9971C11.2355 17.4348 10.1521 17.6537 8.99935 17.6537Z"
      fill="#1FB787"
    />
  </svg>
);

export default Check;
