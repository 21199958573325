import * as React from "react";

function Instagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={19}
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path
        d="M19.86 5.88a6.242 6.242 0 00-.442-2.216 4.595 4.595 0 00-1.093-1.594 4.887 4.887 0 00-1.679-1.039 7.281 7.281 0 00-2.302-.42C13.332.568 13.01.556 10.44.556c-2.571 0-2.9 0-3.906.055a7.28 7.28 0 00-2.3.42 4.883 4.883 0 00-1.68 1.039c-.477.453-.85.996-1.093 1.594a6.265 6.265 0 00-.441 2.185c-.046.963-.06 1.268-.06 3.71s0 2.754.06 3.71c.016.748.164 1.486.44 2.187.245.598.618 1.14 1.095 1.594.478.453 1.05.807 1.68 1.038a7.25 7.25 0 002.3.45c1.014.043 1.335.056 3.906.056s2.901 0 3.907-.056a7.263 7.263 0 002.301-.419c.63-.232 1.201-.586 1.678-1.039a4.6 4.6 0 001.095-1.594c.276-.7.425-1.438.44-2.187.046-.962.06-1.267.06-3.71-.002-2.442-.002-2.752-.061-3.709zm-9.427 8.297c-2.69 0-4.868-2.069-4.868-4.623 0-2.554 2.179-4.623 4.868-4.623 1.29 0 2.529.487 3.442 1.354a4.508 4.508 0 011.425 3.27 4.508 4.508 0 01-1.425 3.268 5.002 5.002 0 01-3.442 1.354zm5.061-8.339c-.628 0-1.135-.482-1.135-1.078 0-.141.03-.281.086-.412.057-.13.141-.25.246-.35.106-.1.23-.18.368-.233a1.187 1.187 0 011.237.233c.105.1.189.22.246.35.057.13.086.27.086.412 0 .596-.507 1.078-1.134 1.078z"
        fill="currentColor"
      />
      <path
        d="M10.433 12.557c1.746 0 3.162-1.344 3.162-3.003 0-1.658-1.416-3.003-3.162-3.003S7.27 7.896 7.27 9.554c0 1.659 1.416 3.003 3.162 3.003z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Instagram;
