import * as React from "react";

function Linkedin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={23}
      viewBox="0 0 24 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.783 2.413c0-.487.204-.955.567-1.3A1.988 1.988 0 012.718.576H22.01c.254 0 .506.047.741.14.235.091.449.227.629.398.18.17.322.373.42.596.097.223.147.463.147.704v18.322c0 .242-.05.481-.147.704-.097.224-.24.427-.42.598-.18.17-.393.306-.628.398-.235.093-.487.14-.741.14H2.718c-.254 0-.506-.047-.74-.14a1.944 1.944 0 01-.629-.398 1.835 1.835 0 01-.419-.597 1.758 1.758 0 01-.147-.704V2.413zm9.169 6.55h3.136v1.496c.453-.86 1.611-1.634 3.352-1.634 3.336 0 4.127 1.713 4.127 4.856v5.822H17.19v-5.106c0-1.79-.452-2.8-1.602-2.8-1.595 0-2.258 1.09-2.258 2.8v5.106H9.951V8.963zM4.16 19.366h3.377V8.826H4.161v10.54zM8.02 5.387c.007.275-.044.548-.15.804a2.055 2.055 0 01-.468.684 2.18 2.18 0 01-.71.46 2.272 2.272 0 01-1.685 0 2.18 2.18 0 01-.71-.46 2.056 2.056 0 01-.468-.684 1.971 1.971 0 01-.152-.804c.013-.539.247-1.052.653-1.43a2.233 2.233 0 011.52-.587c.567 0 1.112.21 1.518.588.406.377.64.89.653 1.43z"
        fill="#292929"
      />
    </svg>
  );
}

export default Linkedin;
