import * as React from "react";

function More(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={28}
      height={8}
      viewBox="0 0 28 8"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.5 4.075a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zM4 .575a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm20 0a3.5 3.5 0 100 7 3.5 3.5 0 000-7z" />
    </svg>
  );
}

export default More;
