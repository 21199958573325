"use client";

import { createElement, forwardRef, type ButtonHTMLAttributes } from "react";
import { Loader } from "./loader";
import type { VariantProps } from "./utils";
import { cn, createVariants } from "./utils";

const buttonVariants = createVariants(
  "flex transition-all items-center justify-center h-[46px] px-6 py-2.5 rounded-[42px] uppercase font-semibold cursor-pointer disabled:cursor-not-allowed",
  {
    variants: {
      variant: {
        primaryGradient: cn(
          "text-white",
          "bg-gradient-to-r from-[#008AB2] to-[#19E1A2] hover:from-[#008AB2]/70 hover:to-[#19E1A2]/70"
        ),
        primary: cn(
          "text-[#16464B] dark:text-[#22C994] bg-[#88D7BB] dark:bg-[#1C5C63]",
          "hover:bg-[#CAEDE0] dark:hover:bg-[#24767F]",
          "focus:bg-[#66CCA8] dark:focus:bg-[#144247]",
          "disabled:text-[#88D7BB] disabled:bg-[#AFE4D0] dark:disabled:text-[#1C5C63] dark:disabled:bg-[#103437]"
        ),
        primaryGradientOutlined: cn(
          "text-[#16464B] dark:text-white dark:bg-[#01111D]",
          "gradient-button",
          "hover:text-white hover:bg-[#11373B] dark:hover:bg-[#11373B]",
          "focus:bg-white focus:text-[#16464B]",
          "disabled:bg-white"
        ),
        secondary: cn(
          "text-[#16464B] dark:text-[#22C994]",
          "border-[#74CED8] border-[1px]",
          "hover:text-[#22C994] hover:bg-[#11373B]",
          "focus:bg-white focus:border-[#195157] focus:text-[#22C994]",
          "disabled:bg-white disabled:border-[#195157] disabled:text-[#22C994]"
        ),
        text: cn(
          "text-[#16464B] dark:text-[#22C994]",
          "hover:text-[#22C994] hover:bg-[#11373B]",
          "focus:bg-white focus:text-[#22C994]",
          "disabled:bg-white disabled:text-[#22C994]"
        ),
        wallet: cn(
          "text-default gap-2 border-0 bg-slate-300 dark:bg-slate-500",
          "hover:text-[#22C994] hover:bg-[#11373B]",
          "focus:bg-white focus:text-[#22C994]",
          "disabled:bg-white disabled:text-[#22C994]"
        ),
        walletError: cn(
          "text-default gap-2 border-0 bg-red-300 dark:bg-red-500",
          "hover:text-white ",
          "focus:bg-white "
        ),
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  }
);

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    as?: keyof JSX.IntrinsicElements;
    loading?: boolean;
    startIcon?: JSX.Element;
  } & (
    | {
        icon?: never | false;
      }
    | {
        icon: true;
        "aria-label": string;
      }
  );

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, loading, startIcon, disabled, variant, icon, as, ...props }, ref) => {
    return createElement(
      as ?? "button",
      {
        role: "button",
        tabIndex: 0,
        className: cn(buttonVariants({ variant, className }), {
          "relative w-[46px] p-4 [&>svg]:absolute": icon,
        }),
        ref,
        disabled: disabled || loading,
        title: props.title ?? props["aria-label"],
        type: props.type ?? "button",
        ...props,
      },
      <>
        {(loading || startIcon) && (
          <div className="flex h-full w-7 items-center">{loading ? <Loader /> : startIcon}</div>
        )}
        {props.children}
      </>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
