import * as React from "react";

function Slack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_902_17735)">
        <path d="M7.37 11.118c-1.16 0-2.102.94-2.102 2.1v5.256a2.101 2.101 0 004.202 0v-5.255c0-1.16-.942-2.101-2.1-2.101zM.016 13.219a2.103 2.103 0 004.205 0v-2.103H2.12h-.002c-1.16 0-2.102.942-2.102 2.103zM7.372.574h-.003a2.103 2.103 0 000 4.205H9.47V2.677v-.004c0-1.16-.94-2.099-2.098-2.099zM2.104 10.05H7.37a2.103 2.103 0 000-4.205H2.104a2.103 2.103 0 000 4.205zM17.88 5.844c-1.158 0-2.097.94-2.097 2.099v2.106h2.1a2.103 2.103 0 000-4.205h-.002zM10.534 2.677v5.27a2.101 2.101 0 004.202 0v-5.27a2.101 2.101 0 00-4.202 0zM14.735 18.472c0-1.16-.941-2.1-2.101-2.1h-2.1v2.102a2.1 2.1 0 004.2-.002zM17.9 11.116h-5.266a2.103 2.103 0 000 4.205H17.9a2.103 2.103 0 000-4.205z" />
      </g>
      <defs>
        <clipPath id="clip0_902_17735">
          <path fill="#fff" transform="translate(0 .575)" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Slack;
