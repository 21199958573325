import * as React from "react";

function Facebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.341 2.25a9.75 9.75 0 00-.75 19.471v-7.47h-2.25a.75.75 0 110-1.5h2.25V10.5a3.003 3.003 0 013-3h1.5a.75.75 0 010 1.5h-1.5a1.502 1.502 0 00-1.5 1.5v2.25h2.25a.75.75 0 110 1.5h-2.25v7.47a9.75 9.75 0 00-.75-19.471z" />
    </svg>
  );
}

export default Facebook;
