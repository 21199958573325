import { RainbowKitProvider, darkTheme, lightTheme } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import type { PropsWithChildren } from "react";
import { WagmiConfig } from "wagmi";

import { chains, wagmiClient } from "./client";

export const Web3Provider = (props: PropsWithChildren) => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={{
          lightMode: lightTheme(),
          darkMode: darkTheme(),
        }}
        appInfo={{
          appName: "Quint Staking App",
          learnMoreUrl: "https://quint.io/",
        }}
      >
        {props.children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
};
