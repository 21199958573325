import { type SVGProps } from "react";

const Danger = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
    <path fill="#fff" fillOpacity=".01" d="M0 0h24v24H0z" />
    <path
      fill="#C45858"
      fillRule="evenodd"
      d="M12.767 3.983c.243.1.463.248.65.434l6.167 6.167a2.004 2.004 0 0 1 0 2.833l-6.168 6.167a2 2 0 0 1-2.832 0l-6.168-6.167a2.001 2.001 0 0 1 0-2.833l6.168-6.167a2.003 2.003 0 0 1 2.183-.434Zm-1.474 9.724A1 1 0 0 0 13 13V8a1 1 0 1 0-2 0v5a1 1 0 0 0 .293.707Zm0 3a1 1 0 1 0 1.414-1.414 1 1 0 0 0-1.414 1.414Z"
      clipPath="evenodd"
    />
  </svg>
);

export default Danger;
