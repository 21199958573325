import * as React from "react";

function Telegram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path d="M22.022 2.975a1.497 1.497 0 00-1.527-.26L3.191 9.514a1.5 1.5 0 00.255 2.867l4.394.879v5.49a1.5 1.5 0 002.561 1.06l2.432-2.432 3.692 3.248a1.487 1.487 0 001.451.302 1.487 1.487 0 001.002-1.093l3.526-15.387a1.496 1.496 0 00-.483-1.472zM17.517 19.5l-7.725-6.797 11.123-8.033-3.398 14.83z" />
    </svg>
  );
}

export default Telegram;
