import { type SVGProps } from "react";

const SandClock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0.666748H10V5.66675L6.66667 9.00008L10 12.3334V17.3334H0V12.3334L3.33333 9.00008L0 5.66675V0.666748ZM8.33333 12.7501L5 9.41675L1.66667 12.7501V15.6667H8.33333V12.7501ZM5 8.58341L8.33333 5.25008V2.33341H1.66667V5.25008L5 8.58341ZM3.33333 4.00008H6.66667V4.62508L5 6.29175L3.33333 4.62508V4.00008Z"
      fill="#BE9FDD"
    />
  </svg>
);

export default SandClock;
