import * as React from "react";

function Discord(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23.525 15.92L20.34 5.302a1.495 1.495 0 00-.866-.957l-.05.015.051-.018a17.422 17.422 0 00-3.237-.966.75.75 0 00-.293 1.471c.42.084.833.182 1.236.295a.748.748 0 01-.418 1.414A17.705 17.705 0 008.02 6.54a.75.75 0 01-.522-1.395l.003.001c.404-.113.816-.211 1.238-.295a.75.75 0 00-.293-1.471c-1.108.216-2.192.54-3.237.966a1.496 1.496 0 00-.866.957L1.158 15.92a1.508 1.508 0 00.458 1.568c.09.077.18.154.274.23l.006.005c1.522 1.234 3.516 2.177 5.768 2.727a.75.75 0 00.355-1.457 15.064 15.064 0 01-2.938-1.038h.007a.75.75 0 01.806-1.24C7.674 17.506 9.91 18 12.34 18c2.432 0 4.67-.493 6.45-1.286a.749.749 0 01.804 1.242h.007c-.941.446-1.926.793-2.938 1.037a.75.75 0 00.355 1.457c2.252-.55 4.246-1.493 5.768-2.727l.006-.006c.093-.075.185-.152.274-.23a1.508 1.508 0 00.458-1.567zM9.341 14.624a1.124 1.124 0 110-2.249 1.124 1.124 0 010 2.249zm6 0a1.124 1.124 0 110-2.249 1.124 1.124 0 010 2.249z" />
    </svg>
  );
}

export default Discord;
