import { type SVGProps } from "react";

const CheckSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" {...props}>
    <path
      d="M11.259 0.991258C11.4934 1.22567 11.625 1.54355 11.625 1.87501C11.625 2.20646 11.4934 2.52435 11.259 2.75876L5.00901 9.00876C4.7746 9.2431 4.45672 9.37474 4.12526 9.37474C3.79381 9.37474 3.47592 9.2431 3.24152 9.00876L0.741515 6.50876C0.513817 6.27301 0.387824 5.95725 0.390672 5.62951C0.39352 5.30176 0.524981 4.98824 0.756742 4.75648C0.988502 4.52472 1.30202 4.39326 1.62976 4.39041C1.95751 4.38757 2.27326 4.51356 2.50901 4.74126L4.12526 6.35751L9.49152 0.991258C9.72592 0.756919 10.0438 0.625275 10.3753 0.625275C10.7067 0.625275 11.0246 0.756919 11.259 0.991258Z"
      fill="white"
    />
  </svg>
);

export default CheckSquare;
