"use client";

import {
  SnackbarContent,
  SnackbarProvider,
  useSnackbar,
  type OptionsObject,
  type SnackbarKey,
  type SnackbarProviderProps,
} from "notistack";
import {
  forwardRef,
  isValidElement,
  useCallback,
  useMemo,
  type ComponentType,
  type FC,
  type ReactNode,
  type SVGProps,
} from "react";
import { Button } from "./button";
import { Check } from "./icons";
import CloseIcon from "./icons/close";
import Danger from "./icons/danger";
import { cn } from "./utils";

export type AppToastVariant = "success" | "error";
export type AppToastProps = {
  message?: string | ReactNode;
  closeToast?: (key: string | number) => void;
  closable?: boolean;
  title?: string;
  actionLink?:
    | {
        label: string;
        onClick: () => void;
      }
    | ReactNode;
  variant: AppToastVariant;
};

const iconMap: Record<AppToastVariant, ComponentType<SVGProps<SVGSVGElement>>> = {
  error: Danger,
  success: Check,
};

export const Toast = forwardRef<HTMLDivElement, AppToastProps & OptionsObject & { id: SnackbarKey }>(
  ({ message, closeToast, closable, title, actionLink, id: toastKey, variant }, ref) => {
    const showCloseButton = toastKey && closeToast && closable;
    const Icon = iconMap[variant];

    return (
      <SnackbarContent ref={ref}>
        <div className="flex grow rounded-[3px] bg-[#0D2435] text-white dark:bg-white dark:text-[#292929] sm:max-w-[410px]">
          <div
            className={cn("h-full w-[3px]", {
              "bg-[#C45858]": variant === "error",
              "bg-[#3DBC94]": variant === "success",
            })}
          />
          <div className="p-4 pt-[18px]">
            <Icon />
          </div>
          <div className="relative flex grow flex-col p-4">
            {showCloseButton && (
              <div className="absolute right-[6px] top-[6px]">
                <Button icon onClick={() => closeToast(toastKey)} variant="text" aria-label="Close toast">
                  <CloseIcon />
                </Button>
              </div>
            )}
            {title && (
              <div className="mb-3 flex items-center pr-12">
                <p className="font-semibold">{title}</p>
              </div>
            )}
            <p
              className={cn("text-[14px] line-clamp-4", {
                "pr-12": showCloseButton && !title,
              })}
            >
              {message}
            </p>
            {actionLink &&
              (isValidElement(actionLink) ? (
                actionLink
              ) : (
                <Button
                  variant="text"
                  className="mr-auto mt-2"
                  onClick={(actionLink as { onClick: () => void }).onClick}
                >
                  {(actionLink as { label: string }).label}
                </Button>
              ))}
          </div>
        </div>
      </SnackbarContent>
    );
  }
);

Toast.displayName = "Toast";

export const AppToastProvider: FC<{ children?: ReactNode } & SnackbarProviderProps> = ({ children, ...rest }) => (
  <SnackbarProvider
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    Components={{ success: Toast, error: Toast }}
    classes={{ containerRoot: "!top-[112px]" }}
    preventDuplicate={false}
    {...rest}
  >
    {children}
  </SnackbarProvider>
);

export type UseToastProps = Omit<OptionsObject, "variant"> & AppToastProps;

export const useToast = () => {
  const { enqueueSnackbar: notistackEnqueueToast, closeSnackbar: closeToast } = useSnackbar();

  const enqueueToast = useCallback(
    ({ message, closable = true, ...rest }: UseToastProps) =>
      notistackEnqueueToast(message, {
        closeToast,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        closable,
        message,
        ...rest,
      }),
    [closeToast, notistackEnqueueToast]
  );

  return useMemo(() => ({ enqueueToast, closeToast }), [closeToast, enqueueToast]);
};
