import { type BigNumberish } from "ethers";
import { formatUnits } from "ethers/lib/utils";

/**
 * Format BigNumber with ethers
 * @param bigNumber Tokens in BigNumber
 * @returns string number
 */
export const formatBigNumber = (bigNumber?: BigNumberish, tokenDecimals = 18) => {
  return parseFloat(formatUnits(bigNumber || "0", tokenDecimals)).toFixed(4);
};

export default formatBigNumber;
