import { Web3Provider } from "@quint/services/web3";
import { AppToastProvider } from "@quint/ui/toast";
import { api } from "@quint/utils/api";
import { ThemeProvider } from "next-themes";
import { type AppType } from "next/app";
import { hanken, inter, poppins, satoshi } from "../assets/fonts";
import "../styles/index.css";

const MyApp: AppType = ({ Component, pageProps }) => {
  return (
    <>
      <style jsx global>{`
  :root {
    --font-inter: ${inter.style.fontFamily};
    --font-poppins: ${poppins.style.fontFamily};
    --font-satoshi: ${satoshi.style.fontFamily};
    --font-hanken: ${hanken.style.fontFamily};
  }
}`}</style>
      <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
        <AppToastProvider>
          <Web3Provider>
            <Component {...pageProps} />
          </Web3Provider>
        </AppToastProvider>
      </ThemeProvider>
    </>
  );
};
4;

export default api.withTRPC(MyApp);
