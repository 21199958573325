import * as React from "react";

function MenuIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={19}
      height={17}
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.273.575h18v2h-18v-2zm6 7h12v2h-12v-2zm-6 7h18v2h-18v-2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default MenuIcon;
