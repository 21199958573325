import { type SVGProps } from "react";
const Light = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" {...props}>
    <defs>
      <linearGradient id="a" x1={149.99} x2={234.01} y1={119.24} y2={264.76} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="currentColor" />
        <stop offset={0.45} stopColor="currentColor" />
        <stop offset={1} stopColor="currentColor" />
      </linearGradient>
      <symbol id="b" viewBox="0 0 384 384">
        <circle cx={192} cy={192} r={84} fill="url(#a)" stroke="currentColor" strokeMiterlimit={10} strokeWidth={6} />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={24}
          d="M192 61.66V12m0 360v-49.66m92.17-222.51 35.11-35.11M64.72 319.28l35.11-35.11m0-184.34L64.72 64.72m254.56 254.56-35.11-35.11M61.66 192H12m360 0h-49.66"
        >
          <animateTransform
            additive="sum"
            attributeName="transform"
            dur="6s"
            repeatCount="indefinite"
            type="rotate"
            values="0 192 192; 45 192 192"
          />
        </path>
      </symbol>
    </defs>
    <use xlinkHref="#b" width={384} height={384} transform="translate(64 64)" />
  </svg>
);
export default Light;
