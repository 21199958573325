import { connectorsForWallets, getDefaultWallets } from "@rainbow-me/rainbowkit";
import { configureChains, createClient } from "wagmi";
import { bsc, bscTestnet } from "wagmi/chains";
import { injectedWallet, braveWallet, trustWallet } from "@rainbow-me/rainbowkit/wallets";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "@wagmi/core/providers/jsonRpc";

const { chains, provider, webSocketProvider } = configureChains(
  [bsc, ...(process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true" ? [bscTestnet] : [])],
  [
    publicProvider({ priority: 1 }),
    jsonRpcProvider({
      priority: 0,
      rpc: (chain) => ({
        http: "https://capable-hardworking-dream.bsc.quiknode.pro/6dd02605b0ad837b15b15b5f9a17fc5215f9015a/",
      }),
    }),
  ]
);

const { wallets } = getDefaultWallets({
  appName: "Quint Staking App",
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: "Other",
    wallets: [injectedWallet({ chains }), trustWallet({ chains }), braveWallet({ chains })],
  },
]);
export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});

export { chains };
